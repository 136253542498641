export const variables = {
  primaryColor: '#ff5500',
  secondaryColor: '#266dd3',
  backgroundGrey: '#f5f5f5',
  borderThickness: '5px',
  imageSize: '120px',
  gap: '10px',
  bodyFontSize: '1.2rem',
  BR: '15px'
};
