// Right now you can add 1 video per page and this code will work
// Will need to add a loop here if you want to add more than 1 video per page

document.addEventListener('DOMContentLoaded', function() {

  const echoEmbeddedVideoClass = document.querySelector('figure.is-type-video');
  if (echoEmbeddedVideoClass){
    const placeholderVideoHeight = echoEmbeddedVideoClass.clientHeight
    const placeholderVideoWidth = echoEmbeddedVideoClass.clientWidth

    if (echoEmbeddedVideoClass.clientHeight > 0) {
      echoEmbeddedVideoClass.addEventListener('click', () => {
          // Select the iframe after it's been inserted into the DOM
          const iframe = document.querySelector('.container-lazyload > iframe');
          iframe.style.height = placeholderVideoHeight + 'px';
          iframe.style.width = placeholderVideoWidth + 'px';
      });
    }
  }

});



