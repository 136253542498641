// import { variables } from '../variables.js';

const categories = {
  'construction-safety': 'Construction',
  'dangerous-substances': 'COSHH',
  'falls': 'Falls',
  'featured': 'Featured',
  'first-aid': 'First Aid',
  'food': 'Food',
  'harassment': 'Harassment',
  'healthcare': 'Healthcare',
  'health-and-safety-law': 'Health & Safety Law',
  'office-safety': 'Office Safety',
  'risk-assessment': 'Risk Assessment',
  'safety-leadership': 'Safety Leadership',
  'learning-management': 'Training Staff',
  'transportation':'Transportation',
  'wellbeing': 'Wellbeing',
  'young-people': 'Young People',
}

// Check if '/news' is in the URL path
if (window.location.pathname.includes('/news')) {
  // Get the query parameters from the URL
  const queryParams = new URLSearchParams(window.location.search);
  // Check if the 'category' parameter exists
  if (queryParams.has('category')) {
    // Grab the value of the 'category' parameter
    const category = queryParams.get('category');
    setSelectedCategory(category);
  } else {
    assignTitle(null)
  }
}


jQuery(document).ready(function($) {

  $('.article-categories-container a').on('click', function(e) {
      e.preventDefault(); // Prevent the default link action (page refresh)

      const categoryElement = $(this).attr('href');
      const category = categoryElement.split('/category/')[1].replace('/', '');

      setSelectedCategory(category);
      // Make an AJAX request to the server
      $.ajax({
          url: ajax_obj.ajax_url, // The AJAX URL provided by WordPress
          type: 'POST',
          data: {
              action: 'filter_articles', // Custom AJAX action
              category: category // Category slug to filter by
          },
          beforeSend: function() {
              $('.echo-3-posts-container ul').html('<p>Loading articles...</p>');
          },
          success: function(response) {
              $('.echo-3-posts-container ul').html(response);
          },
          error: function() {
              $('.echo-3-posts-container ul').html('<p>There was an error. Please try again later.</p>');
          }
      });
  });
});

function assignTitle(urlKeyWhichUserIsOn) {
  let title;
  if (urlKeyWhichUserIsOn) {
        title = categories[urlKeyWhichUserIsOn];
  } else {
    title = 'Health & Safety Articles';
  }
  document.querySelector('.blog-page-all-articles-container h2').innerHTML = title
  document.querySelector('.blog-page-all-articles-container h2').style.visibility = 'visible'
}

function setElementClasses(element, cssText){
  element.classList = cssText;
  // const anchor = element.querySelector('a');
  // anchor.classList = cssText;
}


function setSelectedCategory(category) {
  const urlKeyWhichUserIsOn = Object.keys(categories).find(item => category.includes(item))
  assignTitle(urlKeyWhichUserIsOn)
  const categoriesHtmlList = document.querySelectorAll('.article-categories-container li');
  const categoriesHtmlListArray = Array.from(categoriesHtmlList);

  if (categoriesHtmlListArray.length > 0) {
    const selectedCategory = categoriesHtmlListArray.find(listItem => {
      const anchor = listItem.querySelector('a'); // Find the anchor tag inside each list item
      return anchor && anchor.getAttribute('href').includes(category); // Check if the href matches the category
    });
    categoriesHtmlListArray.forEach(listItem => { // Reset selected item
      setElementClasses(listItem, 'cat-item');
    })
    if (selectedCategory) {
      setElementClasses(selectedCategory, `cat-item background-secondary`);
    }
  }
}
